import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["BrowserPlugin"] */ "/home/vsts/work/1/s/src/app/[[...slug]]/plugins/browser-plugin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreviewIdSwitcher"] */ "/home/vsts/work/1/s/src/app/[[...slug]]/plugins/preview-id-switcher.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/components/Breadcrumb/Breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Locator"] */ "/home/vsts/work/1/s/src/components/ContentElementsGdds/locator/locator.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/OrderHistory/OrderHistoryContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/Product/DetailContainer/ProductDetailContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/Product/Listing/ProductListingContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/Search/search-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/components/ShoppingBasket/ShoppingBasketContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/features/download-center/scenes/country.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/features/download-center/scenes/group.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/src/features/download-center/scenes/nordics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampusCatalog"] */ "/home/vsts/work/1/s/src/scenes/Campus/CampusCatalog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampusCourseDetails"] */ "/home/vsts/work/1/s/src/scenes/Campus/CampusCourseDetails.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampusCourseDetailsBooked"] */ "/home/vsts/work/1/s/src/scenes/Campus/CampusCourseDetailsBooked.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampusHome"] */ "/home/vsts/work/1/s/src/scenes/Campus/CampusHome.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampusLanding"] */ "/home/vsts/work/1/s/src/scenes/Campus/CampusLanding.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Checkout"] */ "/home/vsts/work/1/s/src/scenes/Checkout/Checkout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Default"] */ "/home/vsts/work/1/s/src/scenes/Default/Default.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotFound"] */ "/home/vsts/work/1/s/src/scenes/Error/NotFound.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Frameless"] */ "/home/vsts/work/1/s/src/scenes/Frameless/Frameless.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CiamDeletionPage"] */ "/home/vsts/work/1/s/src/scenes/Group/ciam-deletion-page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Detail"] */ "/home/vsts/work/1/s/src/scenes/Group/Detail.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Home"] */ "/home/vsts/work/1/s/src/scenes/Group/Home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InsightsDetail"] */ "/home/vsts/work/1/s/src/scenes/Group/InsightsDetail.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InsightsLanding"] */ "/home/vsts/work/1/s/src/scenes/Group/InsightsLanding.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Landing"] */ "/home/vsts/work/1/s/src/scenes/Group/Landing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Home"] */ "/home/vsts/work/1/s/src/scenes/Home/Home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Landingpage"] */ "/home/vsts/work/1/s/src/scenes/LandingPage/Landingpage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useIsoLang"] */ "/home/vsts/work/1/s/src/scenes/MetaData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Content"] */ "/home/vsts/work/1/s/src/scenes/Nordics/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Home"] */ "/home/vsts/work/1/s/src/scenes/Nordics/home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Landing"] */ "/home/vsts/work/1/s/src/scenes/Nordics/landing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PressPortalScene"] */ "/home/vsts/work/1/s/src/scenes/Nordics/press-portal/press-portal-scene.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CheckoutIntro"] */ "/home/vsts/work/1/s/src/scenes/ShoppingBasket/CheckoutIntro/CheckoutIntro.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideocenterChannel"] */ "/home/vsts/work/1/s/src/scenes/Video/VideocenterChannel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideocenterOverviewScene"] */ "/home/vsts/work/1/s/src/scenes/Video/VideocenterOverview.tsx");
