// types
import type { ItemProps } from 'components/ContentElementsGdds/hero-image/hero-image-gdds.types';
import type { DefaultItemProps } from '../default.types';

export function getHeroImageItems({ items }: { items: DefaultItemProps[] }): ItemProps[] {
  if (!items || items.length === 0) return [];

  return items.map((item) => ({
    ...item,
    emphasize: 'title',
    isInSubscription: null,
    textbox: {
      color: item.textColor,
      headline: item.headline,
      link: item.link,
      position: item.position,
      subline: item.subheadline ?? '',
    },
    colorVariant: item.textColor,
    type: 'hero_a_b_item',
    imageAlt: item.pictureAlt,
  }));
}
