// types
import type { IntroTextProps } from './types';

// components
import { IntroText as DefaultIntroText } from './default-intro-text';
import { GroupIntroText } from './group/group-intro-text';

// utils
import { useGroup } from 'utils/hooks/use-group';

export function IntroText(props: Readonly<IntroTextProps>) {
  const isGroup = useGroup();

  if (isGroup) {
    return <GroupIntroText {...props} />;
  } else {
    return <DefaultIntroText {...props} />;
  }
}
