import { Container } from '@geberit/gdds';

// types
import { TitleFormats, type TitleProps } from './title.types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { InlineEdit } from 'components/ContentCreator/InlineEdit';

// utils
import { decodingContent } from 'utils/decodingContent';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useGdds } from 'utils/hooks/use-gdds';

export function Title({
  title,
  subtitle,
  Format,
  pageHeadline = false,
  className,
  formatClassName,
  hasIntroText,
  titlePreviewId,
  subtitlePreviewId,
  isSectionReference = false,
  ...props
}: Readonly<TitleProps>) {
  // generate classNames depending on pageHeadline
  const wrapperClass = pageHeadline ? 'grid-container c-page-headline' : 'separate-title';
  const introTextClass = hasIntroText ? 'has-introtext' : '';
  const parsedTitle = decodingContent(title);
  const parsedSubtitle = decodingContent(subtitle);
  const isGdds = useGdds();

  if (!parsedTitle && !parsedSubtitle) return null;

  return isGdds ? (
    <Container
      maxContentWidth={buildSize(gridSizes.gddsFullGrid)}
      className={`title--block ${className ?? wrapperClass} ${introTextClass}`}
    >
      <Headline title={parsedTitle} subtitle={parsedSubtitle} tag={TitleFormats.h1} />
    </Container>
  ) : (
    <div className={`title--block ${className ?? wrapperClass} ${introTextClass}`}>
      <Format {...props} className={formatClassName}>
        {parsedTitle && (
          <b>
            <InlineEdit previewId={titlePreviewId} isSectionReference={isSectionReference}>
              {parsedTitle}&nbsp;
            </InlineEdit>
          </b>
        )}
        {parsedSubtitle && (
          <InlineEdit previewId={subtitlePreviewId} isSectionReference={isSectionReference}>
            {parsedSubtitle}
          </InlineEdit>
        )}
      </Format>
    </div>
  );
}
