import styled, { css } from 'styled-components';

export const StyledHeroImageThemedWrapper = styled.div<{
  emptyContentBoxIds: number[];
  isSlider: boolean;
}>(() => {
  return css`
    --gdds-hero-image-max-width: 80rem;

    .gdds & {
      --gdds-hero-image-max-width: 78rem;
    }

    ${removeEmptyContentBoxes}

    &.hide-scrollbar-indicator {
      span svg {
        display: none;
      }
    }

    &.disable-animation {
      div[color] {
        animation: none !important;
        top: 35% !important;
        opacity: 0.8;

        &:before {
          animation: none !important;
        }
      }
    }

    &.disable-slider-controls {
      .carousel-left-button,
      .carousel-right-button {
        display: none;
      }
      // removes the slider left button column
      [src] > div > div > div:nth-child(1) {
        display: none;
      }
    }

    &.wide-layout {
      & > div {
        height: auto;
      }
      [src] {
        height: auto;
        aspect-ratio: 3 / 4;

        @media ${({ theme }) => theme.device.medium} {
          aspect-ratio: 16 / 6;
        }
      }
    }
  `;
});

const StyledHeroImageThemedDefault = styled(StyledHeroImageThemedWrapper)(() => {
  return css`
    h1,
    h2 {
      display: flex;
      flex-direction: column-reverse;
    }

    h1 span,
    h2 span {
      ${() => getBasicFontStyles()}
      white-space: pre-line !important;
    }

    h1 b,
    h2 b {
      display: block;
    }
  `;
});

export const StyledHeroImageThemed = {
  Default: StyledHeroImageThemedDefault,
};

function removeEmptyContentBoxes({
  emptyContentBoxIds,
  isSlider,
}: {
  emptyContentBoxIds: number[];
  isSlider: boolean;
}) {
  function removeBox({ emptyContentBoxId }: { emptyContentBoxId: number }) {
    return css`
      [data-swiper-slide-index='${emptyContentBoxId}'] {
        [color] {
          display: none;
        }
      }
    `;
  }

  // if there is only one hero image, there is no slider
  // so we target the content box directly
  if (!isSlider && emptyContentBoxIds.length === 1) {
    return css`
      [role='img'] [color] {
        display: none;
      }
    `;
  }

  return css`
    ${emptyContentBoxIds.map((emptyContentBoxId) => removeBox({ emptyContentBoxId }))}
  `;
}

function getBasicFontStyles() {
  return css`
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 2.25rem;

    @media ${({ theme }) => theme.device.medium} {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
    @media ${({ theme }) => theme.device.large} {
      font-size: 2.625rem;
      line-height: 3.25rem;
    }
    @media ${({ theme }) => theme.device.xlarge} {
      font-size: 2.875rem;
      line-height: 3.75rem;
    }

    b {
      font-weight: 500;
    }
  `;
}
