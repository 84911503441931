import { Container, Row, Col, Divider } from '@geberit/gdds';

// styles
import styles from '../intro-text.module.scss';

// types
import type { IntroTextProps } from '../types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { IntroHeadline } from './intro-headline';

// utils
import { getClassName } from '../utils';
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useThemeName } from 'utils/hooks/use-theme';
import { useSectionId } from 'utils/hooks/use-section-id';

export function GroupIntroText({
  className,
  title,
  emphasize,
  subtitle,
  intro,
  text,
  position,
  contentIndex,
  contentLength,
  spacing,
  border,
  backgroundColor,
  titlePreviewId = '#st_title',
  subtitlePreviewId = '#st_subtitle',
  disableInEdit = false,
}: Readonly<IntroTextProps>) {
  const hasHeadline = Boolean(title || subtitle);
  const themeName = useThemeName();
  const isDefault = themeName === 'DEFAULT';
  const sectionId = useSectionId(title, '', false, contentIndex);
  const colSize = [4, 8, 12];

  return (
    <div
      className={getClassName({
        hasHeadline,
        contentLength: contentLength ?? 0,
        contentIndex: contentIndex ?? 0,
        spacing,
        backgroundColor,
        isNordics: false,
        isDefault,
        className,
      })}
      id={sectionId}
    >
      <Container maxContentWidth={buildSize(gridSizes.twoThird)}>
        {hasHeadline && (
          <IntroHeadline
            position={position}
            colSize={colSize}
            title={title}
            subtitle={subtitle}
            format={Formats.h2}
            emphasize={emphasize}
            intro={intro}
            text={text}
            titlePreviewId={titlePreviewId}
            subtitlePreviewId={subtitlePreviewId}
            disableInEdit={disableInEdit}
          />
        )}
        {intro && (
          <Row className={classNameBuilder(position && styles[position], styles.row)}>
            <Col size={colSize}>
              <InnerHtml
                content={intro}
                as="div"
                className={styles.intro}
                isGdds
                previewId={disableInEdit ? undefined : '#st_content'}
              />
            </Col>
          </Row>
        )}
        {text && (
          <Row className={classNameBuilder(position && styles[position], styles.row)}>
            <Col size={colSize}>
              <InnerHtml
                content={text}
                as="div"
                className={classNameBuilder(styles.text, intro && styles.withMargin)}
                isGdds
              />
            </Col>
          </Row>
        )}
      </Container>
      {border !== 'disable-border' && (
        <Container maxContentWidth="78rem">
          <Row>
            <Col size={colSize} className={styles.border}>
              <Divider alignment="horizontal" />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
