import styled, { css } from 'styled-components';

// types
import type { DefaultTheme } from 'styled-components';

// components
import { Image } from 'components/image/image';

export const StyledVideoPlayer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledContainer = styled.div`
  position: absolute;

  top: 0;

  height: 100%;
  width: 100%;
  max-width: 80rem;

  .gdds & {
    max-width: 78rem;
  }

  padding-right: 1rem;
  padding-left: 1rem;
  margin: 0 auto;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.medium} {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  z-index: 10;
`;

export const StyledCol = styled.div`
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  display: inline-block;

  @media ${({ theme }) => theme.device.medium} {
    min-width: 50%;
    max-width: 75%;
  }
  @media ${({ theme }) => theme.device.large} {
    min-width: 33.33%;
    max-width: 50%;
  }
`;

export const StyledVideo = styled.div<{ showVideo: boolean }>((props) => {
  const { showVideo } = props;

  return css`
    visibility: ${showVideo ? 'unset' : 'block'};
    display: ${showVideo ? 'block' : 'none'};
    position: absolute;
    top: 0;
    width: 100%;

    ${getStyleForVideoFullScreen()}
  `;
});

export const StyledImage = styled(Image)<{ theme: DefaultTheme }>(() => {
  return css`
    object-fit: cover;
    width: 100%;
  `;
});

function getStyleForVideoFullScreen() {
  return css`
    height: inherit;

    &&& .mi-player {
      height: inherit !important;

      .mi-content {
        height: inherit !important;
      }

      video {
        height: inherit !important;
        object-fit: cover;
      }
    }
  `;
}
