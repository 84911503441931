'use client';

// styles
import styles from './detail.module.scss';

// components
import Layout from './Layout';
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { FullGridImage } from 'components/ContentElementsGdds/FullGridImage/full-grid-image';
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Wrapper } from 'components/ContentElements/Wrapper';
import HeroImageC from 'components/ContentElementsGdds/hero-image/hero-image-c';

// utils
import { useThemeName } from 'utils/hooks/use-theme';
import { hasContentAreas, useContent } from 'components/Page/page-hooks';

type DetailProps = {
  heroTypeC: HeroImageCProps;
};

export function Detail() {
  const themeName = useThemeName();
  const content = useContent<{}, DetailProps>();

  if (!hasContentAreas(content)) {
    return null;
  }

  const {
    metaData,
    breadcrumbs,
    page: { headline, subline, emphasize, heroTypeC },
    contentAreas,
    ...props
  } = content;

  const fullGridImage = contentAreas.content?.find((area) => area.type === 'full_grid_image');

  return (
    <Layout metaData={metaData}>
      <Breadcrumb items={breadcrumbs} className={styles.breadcrumbs} />
      {heroTypeC?.image && (
        <div className={styles.heroImage}>
          <HeroImageC {...heroTypeC} />
        </div>
      )}

      {fullGridImage && (
        <Wrapper isContainerChild={false} previewId={fullGridImage.previewId}>
          <FullGridImage {...fullGridImage} />
        </Wrapper>
      )}
      {(headline || subline) && (
        <Headline
          format={Formats.h1}
          title={headline}
          subtitle={subline}
          titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h3)}
          subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h3)}
          className={styles.headline}
        />
      )}
      <ContentArea content={contentAreas.content} contentType="content" {...props} />
    </Layout>
  );
}
