import { Row, Col } from '@geberit/gdds';
import capitalize from 'lodash/capitalize';

// style
import styles from './headline.module.scss';

// types
import type { HeadlineProps, TypoProps } from './headlines.types';

// components
import { InlineEdit } from 'components/ContentCreator/InlineEdit';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { classNameBuilder as buildClassName, classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { useThemeName } from 'utils/hooks/use-theme';

const Typo = ({ variant, tag = 'h2', className, children }: TypoProps) => {
  const Element = tag;
  const capitalizedTitleTagName = 'headline' + capitalize(variant ?? tag);
  const themeName = useThemeName();

  return (
    <Element
      className={classNameBuilder(
        'gdds-headline',
        themeName.toLowerCase(),
        styles[capitalizedTitleTagName],
        className,
      )}
    >
      {children}
    </Element>
  );
};

export function Headline({
  title,
  subtitle,
  tag,
  variant,
  titlePreviewId,
  subtitlePreviewId,
  introPreviewId,
  textPreviewId,
  className,
  text,
  intro,
  textClassName,
  introTextClassName,
  isFlexItem,
}: Readonly<HeadlineProps>) {
  if (!title && !subtitle && !text && !intro) return null;

  const parsedTitle = decodingContent(title);
  const parsedSubtitle = decodingContent(subtitle);

  const renderTitle = () =>
    (parsedTitle || parsedSubtitle) && (
      <Typo tag={tag} variant={variant}>
        {parsedTitle && <InlineEdit previewId={titlePreviewId}>{parsedTitle}</InlineEdit>}
        {parsedTitle && parsedSubtitle && <>&nbsp;</>}
        {parsedSubtitle && (
          <Typo tag="span">
            <InlineEdit previewId={subtitlePreviewId}>{parsedSubtitle}</InlineEdit>
          </Typo>
        )}
      </Typo>
    );

  const renderIntro = () =>
    intro && (
      <InnerHtml
        as="div"
        content={intro}
        className={introTextClassName}
        previewId={introPreviewId}
        isGdds
      />
    );

  const renderText = () =>
    text && (
      <InnerHtml
        as="div"
        content={text}
        className={textClassName}
        previewId={textPreviewId}
        isGdds
      />
    );

  const content = (
    <div className={buildClassName(styles.title, className, subtitle && styles.withSubtitle)}>
      {renderTitle()}
      {renderIntro()}
      {renderText()}
    </div>
  );

  return isFlexItem ? (
    content
  ) : (
    <Row justify="center">
      <Col size={[4, 8, 8]} className={styles.headlineWrapper}>
        {content}
      </Col>
    </Row>
  );
}
