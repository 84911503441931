'use client';
import { useSelector } from 'react-redux';

// types
import type { DefaultItemProps } from '../../scenes/Default/default.types';

// utils
import { useContent } from 'components/Page/page-hooks';
import { globalsServerSelector } from 'utils/selectors/globalsSelectors';
import { useIsLeadManagementLanding } from 'utils/hooks/use-is-lead-management-landing';

export function useShowSocialShare() {
  const content = useContent<{}, { heroTileItems: DefaultItemProps[] }>();
  const server = useSelector(globalsServerSelector);
  const isLeadManagementLanding = useIsLeadManagementLanding(server);

  const socialSharing = content?.page?.socialSharing;

  return socialSharing === 'true' && !isLeadManagementLanding;
}
