import key from 'weak-key';

// styles
import styles from './cartTable.module.scss';

// components
import { Translation } from 'components/Translation/Translation';
import { Image } from 'components/image/image';
import { Icon, ICON_COLORS } from 'components/Icon/Icon';

// utils
import { transformPrice } from '../../util/helperFunctions';
import { SimulatedOrderData } from 'components/Checkout/action.types';

type CartTableProps = {
  data: (SimulatedOrderData['lineItemsList'][number] & {
    pricePerItem?: number;
    totalPrice?: number;
  })[];
  vatInc?: boolean;
};

function CartTable({ data, vatInc = false }: Readonly<CartTableProps>) {
  function scrollTo(direction: 'right' | 'left') {
    const scrollableArea = document.getElementById('scrollable_area');
    scrollableArea?.scrollBy(direction === 'right' ? 100 : -100, 0);
  }

  return (
    <div className={`${styles.cartTableWrapper} disable-border`}>
      <div className={`grid-x ${styles.gridContainer}`}>
        <button
          className={`cell shrink ${styles.arrowContainer} align-self-middle`}
          onClick={() => scrollTo('left')}
          onKeyUp={(e) => {
            // for buttons on return the onclick event is triggered
            e.preventDefault();
          }}
          tabIndex={0}
        >
          <Icon symbol="chevron-large-left" color={ICON_COLORS.BLACK} />
        </button>
        <div id="scrollable_area" className={`cell auto ${styles.scrollableArea}`}>
          {' '}
          <table className={`${styles.cartTable}`}>
            <thead>
              <tr>
                <th className={styles.td}></th>
                <th className={styles.td}>
                  <Translation id="web20_checkout_address_basket_table_headings_art" />
                </th>
                <th className={styles.td}>
                  <Translation id="web20_checkout_address_basket_table_headings_product" />
                </th>
                <th className={styles.td}>
                  <Translation
                    id={
                      vatInc
                        ? 'web20_checkout_address_basket_table_headings_price'
                        : 'web20_checkout_address_basket_table_headings_price_excl_vat'
                    }
                  />
                </th>
                <th className={styles.td}>
                  <Translation id="web20_checkout_address_basket_table_headings_number" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((val, ind) => {
                const discountAmount = Number(val.priceVoucherPcs) || 0;
                const isDiscounted = discountAmount > 0;
                const lastElIndex = data.length - 1;

                return (
                  <tr key={key(val)}>
                    <td
                      className={`${styles.td} ${
                        lastElIndex === ind ? styles.tdWithoutBottomBorder : {}
                      }`}
                    >
                      <Image src={val.imageUri} className={styles.productImage} />
                    </td>
                    <td
                      className={`${styles.td} ${
                        lastElIndex === ind ? styles.tdWithoutBottomBorder : {}
                      }`}
                    >
                      {val.sku}
                    </td>
                    <td
                      className={`${styles.td} ${
                        lastElIndex === ind ? styles.tdWithoutBottomBorder : {}
                      }`}
                    >
                      {val.description}
                    </td>
                    <td
                      className={`${styles.td} ${
                        lastElIndex === ind ? styles.tdWithoutBottomBorder : {}
                      }`}
                    >
                      <div>
                        <span>
                          {val.pricePerItem
                            ? transformPrice(Number(val.totalPrice) - discountAmount) //todo remove workaound when original price is available from MBP
                            : transformPrice(
                                Number(val.totalPriceVatExcluding) - discountAmount,
                              )}{' '}
                          <Translation id="web20_checkout_country_default_currency" />
                        </span>

                        {isDiscounted && (
                          <span className={styles.discountAmount}>
                            {discountAmount.toFixed(2)}{' '}
                            <Translation id="web20_checkout_country_default_currency" />
                          </span>
                        )}
                      </div>
                    </td>
                    <td
                      className={`${styles.td} ${
                        lastElIndex === ind ? styles.tdWithoutBottomBorder : {}
                      }`}
                    >
                      {val.quantity}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button
          className={`cell shrink ${styles.arrowContainer} align-self-middle`}
          onClick={() => scrollTo('right')}
          onKeyUp={(e) => {
            // for buttons on return the onclick event is triggered
            e.preventDefault();
          }}
        >
          <Icon symbol="chevron-large-right" color={ICON_COLORS.BLACK} />
        </button>
      </div>
    </div>
  );
}

export default CartTable;
