import { FallbackProps } from 'react-error-boundary';
import { Message } from '../Message/Message';
import { EditWrapper } from '../ContentCreator/EditWrapper';

export const ComponentHasError = ({
  type,
  isPreview,
  previewId,
  error,
}: { type: string; isPreview: boolean; previewId?: string } & FallbackProps) => {
  if (!isPreview) return null;

  const message = `Section "${type}" has an error.`;

  console.error(message, error);

  return (
    <div className="grid-container">
      <EditWrapper previewId={previewId}>
        <Message closable={false} type="warning" content={message} />
      </EditWrapper>
    </div>
  );
};
