import key from 'weak-key';
import { Button, Divider, Typo } from '@geberit/gdds';

// styles
import styles from './cart.module.scss';

// types
import type { CartDownloadsProps } from '../../types';

// components
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { isEmpty } from 'utils/is-empty';

export default function CartDownloads({
  downloads,
  updateDownload,
  downloadZipHandler,
}: Readonly<CartDownloadsProps>) {
  const isMobile = useIsMobile();

  function renderTable() {
    const tableData = downloads.map((download) => {
      const {
        meta: { indexer_filetype: downloadDoctype },
        title,
      } = download;
      const doctype = downloadDoctype ? downloadDoctype[0] : undefined;

      return (
        <tr key={key(download)}>
          <td>{!isEmpty(title) && <InnerHtml as="span" content={decodingContent(title)} />}</td>
          <td>{String(decodingContent(doctype)).toUpperCase()}</td>
          <td>
            <Button
              stylingType="flat"
              symbol="Trash"
              onClick={() => {
                updateDownload(download);
              }}
            >
              <Translation id="web20_downloadcenter_delete" />
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div className={classNameBuilder('c-cart-downloads', styles.downloads, 'nordics')}>
        <table>
          <tbody>{tableData}</tbody>
          <thead>
            <tr>
              <th>
                <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
              </th>
              <th>
                <Translation id="web20_downloadcenter_cartmodal_cell_format" />
              </th>
              <th />
            </tr>
          </thead>
        </table>
      </div>
    );
  }

  const renderTableMobile = () => {
    const tableData = downloads.map((download, index) => {
      const {
        meta: { indexer_filetype: downloadDoctype },
        title,
      } = download;

      return (
        <>
          {index === 0 && <Divider />}
          <div className={styles.download} key={download.id}>
            <div className={styles.download__designation}>
              <Typo variant="p1">{decodingContent(title)}</Typo>
            </div>
            <div className={styles.download__actions}>
              <Typo variant="p1">{decodingContent(downloadDoctype[0]).toUpperCase()}</Typo>
              <Button
                stylingType="flat"
                symbol="Trash"
                onClick={() => {
                  updateDownload(download);
                }}
              >
                <Translation id="web20_downloadcenter_delete" />
              </Button>
            </div>
          </div>
          <Divider />
        </>
      );
    });

    return (
      <div className={styles.downloads}>
        <Typo variant="p1" fontWeight={700}>
          <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
        </Typo>
        {tableData}
      </div>
    );
  };

  const buttonLabel = 'web20_downloadcenter_cartdownload_as_zip';

  return (
    <div>
      {isMobile ? renderTableMobile() : renderTable()}
      <div className={styles.download_button}>
        <Button stylingType="primary" onClick={downloadZipHandler}>
          <Translation id={buttonLabel} />
        </Button>
      </div>
    </div>
  );
}
