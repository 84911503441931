import styled, { css, keyframes } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

// types
import type { StyledContentBoxProps } from './ContentBox.types';

const StyledDefaultContentBox = styled.div<StyledContentBoxProps>((props) => {
  const { animation, color, position, theme } = props;

  return css`
    ${getDefaultStyles({ color, theme })};

    ${getPosition({ position })};

    ${getBackdropStyles({ color })};

    ${() => (animation ? getAnimation({ animation, position }) : undefined)};

    .content-box__heading,
    .content-box__subheading {
      ${() => getBasicFontStyles({ color, theme })}
    }

    .content-box__button {
      align-self: flex-end;

      margin-top: var(--spacing-s);
      @media ${({ theme }) => theme.device.medium} {
        margin-top: var(--spacing-l);
      }
    }
  `;
});

const StyledContentBoxDefault = styled(StyledDefaultContentBox)<StyledContentBoxProps>(() => {
  return css`
    h1 {
      display: flex;
      flex-direction: column-reverse;
    }
  `;
});

const StyledContentBoxNord = styled(StyledDefaultContentBox)<StyledContentBoxProps>(() => {
  return css`
    &&& .content-box__subheading {
      font-family: 'QuickbrushBaltics', helvetica, arial, sans-serif;
    }
  `;
});

const StyledContentBoxKolo = styled(StyledDefaultContentBox)<StyledContentBoxProps>((props) => {
  const { theme } = props;
  return css`
    &&& .content-box__heading,
    &&& .content-box__subheading {
      text-transform: uppercase;
      font-weight: ${theme.fontWeights.bold};
    }
  `;
});

const StyledContentBoxTwyford = styled(StyledDefaultContentBox)<StyledContentBoxProps>((props) => {
  const { theme } = props;
  return css`
    &&& .content-box__subheading {
      font-weight: ${theme.fontWeights.regular};
      text-transform: capitalize;
    }
  `;
});

const StyledContentBoxGdds = styled(StyledDefaultContentBox)<StyledContentBoxProps>((props) => {
  const { theme } = props;
  return css`
    &&& .content-box__heading,
    &&& .content-box__subheading {
      text-transform: uppercase;
    }

    &&& h1,
    &&& .content-box__heading,
    &&& .content-box__subheading {
      font-size: 26px;
      line-height: 32px;

      @media ${({ theme }) => theme.device.medium} {
        font-size: 28px;
        line-height: 36px;
      }

      @media ${({ theme }) => theme.device.large} {
        font-size: 32px;
        line-height: 40px;
      }

      @media ${({ theme }) => theme.device.xlarge} {
        font-size: 36px;
        line-height: 44px;
      }
    }

    &&& .content-box__heading--emphasized,
    &&& .content-box__subheading--emphasized {
      font-weight: ${theme.fontWeights.bold};
    }
  `;
});

export const StyledContentBox = {
  Default: StyledContentBoxDefault,
  Gdds: StyledContentBoxGdds,
  Nord: StyledContentBoxNord,
  Kolo: StyledContentBoxKolo,
  Twyford: StyledContentBoxTwyford,
};

function getBackdropStyles({ color }: Pick<StyledContentBoxProps, 'color'>) {
  const colorBackground = color === 'black' ? 'var(--white-80)' : 'var(--black-70)';
  return css`
    background: ${colorBackground};
  `;
}

function getDefaultStyles({
  color,
  theme,
}: Pick<StyledContentBoxProps, 'color'> & { theme: DefaultTheme }) {
  return css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    z-index: ${theme.zIndices['20']};

    h1 {
      box-sizing: border-box;
      width: ${theme.sizes.full};
      max-width: ${theme.sizes.full};
      min-width: min-content;
      color: ${color === 'white' ? theme.colors.white : theme.colors.black};
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
    }
    h1 span {
      white-space: pre-line !important;
    }
  `;
}

function getPosition({ position }: Pick<StyledContentBoxProps, 'position'>) {
  return css`
    top: ${() => (position === 'top' ? '35%' : '65%')};
    transform: ${() => (position === 'top' ? 'translateY(-35%)' : 'translateY(-65%)')};
  `;
}

function getAnimation({
  animation,
  position,
}: Pick<StyledContentBoxProps, 'animation' | 'position'>) {
  if (!animation)
    css`
      animation: '';
    `;

  return css`
    animation: ${animateFadeIn({ position })} 1.5s ease-in both;
  `;
}

const slideUpKeyFrames = keyframes`
  from {
    top: 100%;
  }

  to {
    top: 35%;
  }
`;

const slideDownKeyFrames = keyframes`
  from {
    top: 0%;
  }

  to {
    top: 65%;
  }
`;

function animateFadeIn({ position }: Pick<StyledContentBoxProps, 'position'>) {
  return css`
    ${position === 'bottom' ? slideDownKeyFrames : slideUpKeyFrames}
  `;
}

function getBasicFontStyles({
  color,
  theme,
}: Pick<StyledContentBoxProps, 'color'> & { theme: DefaultTheme }) {
  return css`
    width: ${theme.sizes.full};

    margin-bottom: ${theme.sizes[0]};

    font-weight: ${theme.fontWeights.light};
    font-size: var(--gdds-font-size-h1-s);
    letter-spacing: ${theme.letterSpacings.normal};
    line-height: var(--gdds-line-height-h1-s);

    color: ${color === 'black' ? theme.colors.black : theme.colors.white};

    @media ${({ theme }) => theme.device.medium} {
      font-size: var(--gdds-font-size-h1-m);
      line-height: var(--gdds-line-height-h1-m);
    }
    @media ${({ theme }) => theme.device.large} {
      font-size: var(--gdds-font-size-h1-l);
      line-height: var(--gdds-line-height-h1-l);
    }
    @media ${({ theme }) => theme.device.xlarge} {
      font-size: var(--gdds-font-size-h1-xl);
      line-height: var(--gdds-line-height-h1-xl);
    }

    white-space: break-spaces;
    word-wrap: break-word;
    word-break: break-word;

    b {
      font-weight: ${theme.fontWeights.regular};
      display: block;
    }
  `;
}
