// types
import type {
  ContentElementPayload,
  AccordionPayload,
  AwardsPayload,
  CallToActionButtonPayload,
  CategoryListPayload,
  CiamVerificationPayload,
  CiamPasswordPayload,
  CountrySwitchPayload,
  HeroPictureSliderPayload,
  ImageGalleryPayload,
  InteractiveDescriptionPayload,
  LandingPageIntroPayload,
  LandingPageTextPayload,
  LeadModulePayload,
  LocatorPayload,
  OxomiPayload,
  PictoPayload,
  ProductComparisonPayload,
  ProductTilesPayload,
  ProfilePageEmbeddedPayload,
  TablePayload,
  TeaserTilesPayload,
  TextImagePayload,
  TextImageTeaserPayload,
  TextVideoPayload,
  TilesPayload,
  ToolIntegrationPayload,
  VideoContentPayload,
  ZipCodeSearchPayload,
  FormPayload,
  TextMediaPayload,
  TextMediaColumnsSectionPayload,
  JobSectionPayload,
  ContactPayload,
  HighlightTeaserSectionPayload,
  ExpressiveTilesPayload,
  SectionSlider,
  TabsPayload,
  TeaserTilesNordicsPayload,
  IsEmptyCheckType,
  ShowroomLocatorTeaserPayload,
  FAQAccordionPayload,
} from './content-elements-payload';
import type { AccordionNordicsProps } from '../ContentElementsGdds/NordicsAccordion/accordion.types';
import type { OnlineCatalogAssetsProps } from './OnlineCatalogAssets/OnlineCatalogAssets';

// utils
import { isEmpty } from 'utils/is-empty';
import { Log } from 'services/log';

function isIFrameLink(link: Link | IFrameLink): link is IFrameLink {
  if (link) {
    return 'url' in link;
  }
  return false;
}

function isVideoCategoryLink(link: Link | VideoCategoryLink): link is VideoCategoryLink {
  if (link) {
    return 'targetChannel' in link;
  }
  return false;
}

function isEmptyAccordion(data: AccordionPayload) {
  return (data.titleInAnchor && !data.title) || isEmpty(data.items);
}

function isEmptyAwards(data: AwardsPayload) {
  return (
    (data.titleInAnchor && !(data.title || data.anchor)) ||
    isEmpty(data.blocks.filter((b) => b.title || !isEmpty(b.awards)))
  );
}

function isEmptyCallToActionButton(data: CallToActionButtonPayload) {
  if (data.link?.type === 'iframe' && isIFrameLink(data.link)) {
    return isEmpty(data.text) || isEmpty(data.link.url) || !data.link.height || !data.link.width;
  } else {
    const link = data.link as Link | undefined;
    return !link?.text || !link.target;
  }
}

function isEmptyCategoryList(data: CategoryListPayload) {
  const innerEmptyCatList = data.categoryItems.some(
    (item) => isEmpty(item.title) || isEmpty(item.picture) || isEmpty(item.categoryCatalogId),
  );
  return (data.titleInAnchor && !data.title) || isEmpty(data.categoryItems) || innerEmptyCatList;
}

function isEmptyCiamVerification(data: CiamVerificationPayload) {
  return Boolean(data.titleInAnchor && !data.title);
}

function isEmptyCiamPw(data: CiamPasswordPayload) {
  return Boolean(data.titleInAnchor && !data.title);
}

function isEmptyCountrySwitch(data: CountrySwitchPayload) {
  return Boolean(data.titleInAnchor && !data.title);
}

function isEmptyHeroPictureSlider(data: HeroPictureSliderPayload) {
  return isEmpty(data.items);
}

function isEmptyImageGallery(data: ImageGalleryPayload) {
  const innerEmptyImageGallery = data.imageGallery.some(
    (item) =>
      isEmpty(item.picture) ||
      (!isEmpty(item.link1) && (isEmpty(item.link1?.target) || isEmpty(item.link1?.text))) ||
      (item.link1?.type === 'video_category_link' &&
        isVideoCategoryLink(item.link1) &&
        isEmpty(item.link1?.targetChannel)) ||
      (!isEmpty(item.link2) && (isEmpty(item.link2?.target) || isEmpty(item.link2?.text))) ||
      (item.link2?.type === 'video_category_link' &&
        isVideoCategoryLink(item.link2) &&
        isEmpty(item.link2?.targetChannel)),
  );
  return (
    (data.titleInAnchor && !data.title) || isEmpty(data.imageGallery) || innerEmptyImageGallery
  );
}

function isEmptyInteractiveDescription(data: InteractiveDescriptionPayload) {
  return Boolean(data.titleInAnchor && !data.title) || isEmpty(data.imageMap.image);
}

function isEmptyLandingpageIntro(data: LandingPageIntroPayload) {
  return (data.titleInAnchor && !data.headline) || !data.picture;
}

function isEmptyLandingpageText(data: LandingPageTextPayload) {
  return (data.titleInAnchor && !data.headline) || !data.text || !data['background-color'];
}

function isEmptyLeadModule(data: LeadModulePayload) {
  const innerEmptyModule = data.modules.some(
    (module) =>
      isEmpty(module.headline) ||
      isEmpty(module.icon) ||
      isEmpty(module.text) ||
      isEmpty(module.buttonText),
  );
  return (data.titleInAnchor && !data.title) || isEmpty(data.modules) || innerEmptyModule;
}

function isEmptyLocator(data: LocatorPayload) {
  return (
    (data.titleInAnchor && !data.title) ||
    (data.dataMode !== 'showroom' && isEmpty(data.data)) ||
    // (data.dataMode === 'showroom' && isEmpty(data.showroom)) || --> Editor could possible not figure out whats the problem if he inserts a wrong Locator-Id
    isEmpty(data.searchPlaceholder) ||
    isEmpty(data.label) ||
    (data.dataMode === 'hotel' && isEmpty(data.modelLabel))
  );
}

function isEmptyOxomi(data: OxomiPayload) {
  return !data.oxomiId;
}

function isEmptyPicto(data: PictoPayload) {
  const innerEmptyPicto = data.items.some((item) => isEmpty(item.headline) || isEmpty(item.image));
  return (data.titleInAnchor && !data.title) || isEmpty(data.items) || innerEmptyPicto;
}

function isEmptyProductComparison(data: ProductComparisonPayload) {
  return (
    // here we recieve a string value for titleInAchor
    (data.titleInAnchor === 'true' && !data.title) ||
    isEmpty(data.productItems) ||
    data.productItems.every((p) => isEmpty(p.useCases))
  );
}

function isEmptyProductTiles(data: ProductTilesPayload) {
  return (data.titleInAnchor && !data.title) || isEmpty(data.productItems);
}

function isEmptyProfilePageEmbedded(data: ProfilePageEmbeddedPayload) {
  return Boolean(data.titleInAnchor && !data.title);
}

function isEmptyTable(data: TablePayload) {
  return (data.titleInAnchor && !data.title) || !data.table.includes('<tbody>');
}

function isEmptyTabs(data: TabsPayload) {
  return Boolean((data.titleInAnchor && !data.title) || isEmpty(data.tabs) || data.tabs.length < 2);
}

function isEmptyTeaserTiles(data: TeaserTilesPayload) {
  const innerEmptyTeaserTiles = data.tile.some(
    (tile) =>
      isEmpty(tile.type) ||
      isEmpty(tile.headline) ||
      (tile.type !== 'locator_tile' &&
        tile.type !== 'showroom_locator_tile' &&
        (isEmpty(tile.link) ||
          isEmpty(tile.link.target) ||
          isEmpty(tile.link.target) ||
          isEmpty(tile.link.text))) ||
      (tile.type === 'locator_tile' && isEmpty(tile.targetUrl)) ||
      (tile.type === 'vertical_tile' && isEmpty(tile.image) && isEmpty(tile.video?.videoId)) ||
      (tile.type === 'horizontal_tile' && isEmpty(tile.image) && isEmpty(tile.video?.videoId)),
  );
  return isEmpty(data.tile) || innerEmptyTeaserTiles;
}

function isEmptyTextImage(data: TextImagePayload) {
  return (
    isEmpty(data.images) &&
    isEmpty(data.picture) &&
    isEmpty(data.content) &&
    isEmpty(data.title) &&
    isEmpty(data.subtitle)
  );
}

function isEmptyTextImageTeaser(data: TextImageTeaserPayload) {
  return (
    (data.titleInAnchor && !data.title) ||
    isEmpty(data.content) ||
    isEmpty(data.images) ||
    isEmpty(data.teaserTitle) ||
    isEmpty(data.teaserContent)
  );
}

function isEmptyTextVideo(data: TextVideoPayload) {
  return (data.titleInAnchor && !data.title) || !data.text || !data.video?.videoId;
}

function checkInnerEmptyTiles(tiles: TilesPayload['tiles']) {
  let innerEmptyTiles = false;

  tiles?.forEach((tile) => {
    if (
      !tile.isAutofilled &&
      (isEmpty(tile.title) ||
        isEmpty(tile.content) ||
        (isEmpty(tile.picture) && isEmpty(tile.video.videoId)))
    ) {
      innerEmptyTiles = true;
    }
  });

  return innerEmptyTiles;
}

function checkInnerEmptyItems(items: TilesPayload['items']) {
  let innerEmptyTiles = false;

  if (!items || items?.length < 2) {
    innerEmptyTiles = true;
  }
  items?.forEach((item) => {
    if (!item.isAutofilled && isEmpty(item.video?.videoId) && isEmpty(item.image)) {
      innerEmptyTiles = true;
    }
  });

  return innerEmptyTiles;
}

function isEmptyTiles(data: TilesPayload) {
  // platform/root (data.tiles) & nordics (data.items)
  let innerEmptyTiles = false;
  if (!isEmpty(data.tiles) && checkInnerEmptyTiles(data.tiles)) {
    innerEmptyTiles = true;
  }
  if (!isEmpty(data.items) && checkInnerEmptyItems(data.items)) {
    innerEmptyTiles = true;
  }
  return (
    (data.titleInAnchor && !data.title) ||
    (isEmpty(data.items) && isEmpty(data.tiles)) ||
    innerEmptyTiles
  );
}

function isEmptyToolIntegration(data: ToolIntegrationPayload) {
  return !data.identifier || !data.scriptUrl;
}

function isEmptyVideoContent(data: VideoContentPayload) {
  return (data.titleInAnchor && !data.title) || isEmpty(data.textColor) || !data.video.videoId;
}

function isEmptyZipCodeSearch(data: ZipCodeSearchPayload) {
  return isEmpty(data.title) || !data.inputLabel || !data.errorMessage || !data.fileUrl;
}

function isEmptyForm(data: FormPayload) {
  return !data.target || data.formId.includes('_null_') || data.fieldsets.fields.length < 2;
}

function isEmptyAccordionNordics(data: AccordionNordicsProps) {
  const innerEmptyAccordionNord = data.accordionItems.some((item) => isEmpty(item.label));

  return (
    (data.titleInAnchor && !data.title) || isEmpty(data.accordionItems) || innerEmptyAccordionNord
  );
}

function isEmptyFaqAccordion(data: FAQAccordionPayload) {
  const innerEmptyAccordionNord = data.items.some((item) => isEmpty(item.question));

  return (data.titleInAnchor && !data.title) || isEmpty(data.items) || innerEmptyAccordionNord;
}

function isEmptyLeadModuleNordics(data: LeadModulePayload) {
  const innerEmptyLeadNord = data.modules.some(
    (module) =>
      isEmpty(module.icon) ||
      isEmpty(module.headline) ||
      isEmpty(module.text) ||
      isEmpty(module.buttonText) ||
      (module.type === 'nordics_lead_module_link' && !module.url) ||
      (module.type === 'nordics_lead_module_form' && !module.forms?.[0]?.formId),
  );
  return (data.titleInAnchor && !data.title) || isEmpty(data.modules) || innerEmptyLeadNord;
}

function isEmptyImageGalleryNordics(data: ImageGalleryPayload) {
  let innerEmptyImgGalleryNord = false;
  data.imageGallery.forEach((item) => {
    if (isEmpty(item.picture)) {
      innerEmptyImgGalleryNord = true;
    }
  });
  return (
    (data.titleInAnchor && !data.title) || isEmpty(data.imageGallery) || innerEmptyImgGalleryNord
  );
}

function isEmptyTabsNordics(data: TabsPayload) {
  let innerEmptyTabsNord = false;
  data.tabs.forEach((tab) => {
    if (isEmpty(tab.label)) {
      innerEmptyTabsNord = true;
    }
  });
  return Boolean(
    (data.titleInAnchor && !data.title) ||
      isEmpty(data.tabs) ||
      data.tabs.length < 2 ||
      innerEmptyTabsNord,
  );
}

function isEmptyTeaserTilesNordics(data: TeaserTilesNordicsPayload) {
  return (data.titleInAnchor && !data.title) || isEmpty(data.tiles);
}

function isEmptyTextMedia(data: TextMediaPayload) {
  // used only in nordics project
  // commented out because nordics want to create sections in master with no video(id) inside
  // the editors have to maintain them after content distribution

  return (
    (!data.mediaItems.length &&
      isEmpty(data.title) &&
      isEmpty(data.subtitle) &&
      isEmpty(data.subline) &&
      isEmpty(data.content)) ||
    Boolean(data.titleInAnchor && !data.title)
  );
}

function isEmptyTextMediaColumnsSection(data: TextMediaColumnsSectionPayload) {
  let innerEmptyTextMediaColsNord = false;
  if (data.columns) {
    innerEmptyTextMediaColsNord = data.columns.some((col) => isEmpty(col.text));
  }

  return Boolean(
    isEmpty(data.columns) || innerEmptyTextMediaColsNord || (data.titleInAnchor && !data.title),
  );
}

function isEmptyInteractiveDescriptionNordics(data: InteractiveDescriptionPayload) {
  return (data.titleInAnchor && !data.title) || !data.imageMap.image;
}

function isEmptyProductTilesNordics(data: ProductTilesPayload) {
  return (data.titleInAnchor && !data.title) || isEmpty(data.productItems);
}

function isEmptyJobsSection(data: JobSectionPayload) {
  return !data.label1 || !data.label2 || !data.link;
}

function isEmptyExpressiveTiles(data: ExpressiveTilesPayload) {
  return isEmpty(data.items);
}

function isEmptyHighlightTeaserSection(data: HighlightTeaserSectionPayload) {
  return Boolean(data.titleInAnchor && !data.title);
}

function isEmptyContact(data: ContactPayload) {
  return !data.contact.name;
}

function isEmptySectionSlider(data: SectionSlider) {
  return !data.items.length;
}

function isEmptyOnlineCatalogAssets(data: OnlineCatalogAssetsProps) {
  return Boolean(data.titleInAnchor && !data.title);
}

function isEmptyShowroomLocatorTeaser(data: ShowroomLocatorTeaserPayload) {
  return !data.title;
}

function isEmptyContactTiles(data: any) {
  return isEmpty(data.items);
}

const isEmptyChecks = {
  accordion: isEmptyAccordion,
  faq_accordion: isEmptyFaqAccordion,
  awards: isEmptyAwards,
  call_to_action_button: isEmptyCallToActionButton,
  category_list: isEmptyCategoryList,
  ciam_verification: isEmptyCiamVerification,
  ciam_pw: isEmptyCiamPw,
  country_switch: isEmptyCountrySwitch,
  hero_picture_slider: isEmptyHeroPictureSlider,
  picture_slider: isEmptyHeroPictureSlider,
  image_gallery: isEmptyImageGallery,
  interactive_description: isEmptyInteractiveDescription,
  landingpage_intro: isEmptyLandingpageIntro,
  landingpage_text: isEmptyLandingpageText,
  lead_module: isEmptyLeadModule,
  locator: isEmptyLocator,
  oxomi: isEmptyOxomi,
  picto: isEmptyPicto,
  product_comparison: isEmptyProductComparison,
  product_tiles: isEmptyProductTiles,
  profile_page_embedded: isEmptyProfilePageEmbedded,
  table: isEmptyTable,
  tabs: isEmptyTabs,
  teaser_tiles: isEmptyTeaserTiles,
  text_image: isEmptyTextImage,
  text_image_teaser: isEmptyTextImageTeaser,
  text_video: isEmptyTextVideo,
  tiles: isEmptyTiles,
  tool_integration: isEmptyToolIntegration,
  video_content: isEmptyVideoContent,
  zip_code_search: isEmptyZipCodeSearch,
  form: isEmptyForm,
  accordion_nordics: isEmptyAccordionNordics,
  nordics_lead_module: isEmptyLeadModuleNordics,
  nordics_image_gallery: isEmptyImageGalleryNordics,
  tabs_nordics: isEmptyTabsNordics,
  nordics_teaser_tiles: isEmptyTeaserTilesNordics,
  text_media: isEmptyTextMedia,
  text_media_columns_section: isEmptyTextMediaColumnsSection,
  nordics_interactive_description: isEmptyInteractiveDescriptionNordics,
  nordics_product_tiles: isEmptyProductTilesNordics,
  jobs_section: isEmptyJobsSection,
  expressive_tiles: isEmptyExpressiveTiles,
  highlight_teaser_section: isEmptyHighlightTeaserSection,
  contact: isEmptyContact,
  section_slider: isEmptySectionSlider,
  online_catalog_assets: isEmptyOnlineCatalogAssets,
  showroom_locator_teaser: isEmptyShowroomLocatorTeaser,
  contact_tiles: isEmptyContactTiles,
};

export function isEmptyElement(data: ContentElementPayload) {
  const isEmptyCheck: IsEmptyCheckType = isEmptyChecks[data.type];
  if (isEmptyCheck) {
    return isEmptyCheck(data);
  }

  Log.error('missing is-empty-element implementation for data.type:', data.type);
  return false;
}
