import { MouseEvent } from 'react';
import {
  HeroImageItems as GddsHeroImageItems,
  ItemButtonProps,
} from '@geberit/gdds/dist/esm/heroimage/heroimage.types';

// types
import type { HeroImageThemedItem } from './hero-image-themed.types';

// utils
import { isDefined } from 'utils/ts-utilities';

export function getIdsOfEmptyContentBoxes({ items }:
  { items: (GddsHeroImageItems & { videoType?: string; video?: VideoProp; })[] }
): number[] {
  if (!items || items.length === 0) return [];

  const emptyContentBoxIds = items
    .map((item, index) => {
      const hasForegroundVideo = item.video && item.video.videoId !== '' && item.videoType === 'fwvideo';
      if (item.title === '' && item.subtitle === '' && !hasForegroundVideo) {
        return index;
      }
      return undefined;
    })
    .filter(isDefined);
  return emptyContentBoxIds;
}

export function normalizeItems({
  isMobile,
  items,
  onForegroundVideoClick,
  videoPlayButtonLabel,
}: {
  isMobile: boolean;
  items: (GddsHeroImageItems & { videoType?: string; video?: VideoProp; headline: string })[];
  onForegroundVideoClick: (video: VideoProp) => void;
  videoPlayButtonLabel: string;
}): HeroImageThemedItem[] {
  return items.map((item) => {
    const { video, videoType } = item;

    const hasVideo = video && video.videoId !== '';
    const hasForgroundVideo = videoType === 'fwvideo';

    if (!hasVideo || !hasForgroundVideo) return item;

    const image = {
      src: isMobile ? video.videoPreviewImage.mobile : video.videoPreviewImage.desktop,
      alt: '',
      title: item.image.title ?? '',
    };

    const handleClick = (e: MouseEvent<HTMLButtonElement, Event> | undefined) => {
      e?.preventDefault();
      onForegroundVideoClick(video);
    };

    const button: ItemButtonProps = {
      onClick: handleClick,
      text: videoPlayButtonLabel,
    };

    const _item: HeroImageThemedItem = { ...item, image, button };
    return _item;
  });
}
