import { Dispatch, SetStateAction } from 'react';

// types
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import type { ItemProps, ModifiedItem } from './hero-image-gdds.types';

// utils
import { decodingContent } from 'utils/decodingContent';
import { isEmpty } from 'utils/is-empty';
import { onButtonClick } from 'utils/button-click-handler';

function isVideoAvailable(item: ItemProps) {
  return (
    (!isEmpty(item.video) && !isEmpty(item.video.videoId) && item.heroType === 'bgvideo') ||
    item?.videoType === 'bgvideo'
  );
}

export function getVideoSlide(items: ItemProps[]): ItemProps | undefined {
  if (!items || isEmpty(items)) {
    throw new Error('No video available!');
  }

  return items.find(isVideoAvailable);
}

export function getModifiedItems(
  router: AppRouterInstance,
  {
    items,
    isMobile,
    hideScrollIndicator,
    track,
    translate,
    setIframeShown,
  }: {
    items: ItemProps[];
    isMobile: boolean;
    track: {
      trackEvent: (data: any) => void;
    };
    translate: (key: string, fallbackOnPreview?: boolean) => string;
    setIframeShown: Dispatch<SetStateAction<string | null>>;
    hideScrollIndicator?: boolean;
  },
): ModifiedItem[] {
  const newItems = items.reduce((acc, item) => {
    const {
      link,
      pictureAlt,
      textColor,
      colorVariant,
      imageObjectMobile,
      imageObject,
      headline,
      subline,
      subheadline,
      emphasize,
      textbox,
      video,
      videoType = undefined,
      position = undefined,
    } = item;
    const imageObj = isMobile ? imageObjectMobile : imageObject;
    const boxLink = textbox?.link || link;

    const newCur = {
      image: {
        src: imageObj.url,
        alt: decodingContent(pictureAlt),
        title: decodingContent(pictureAlt),
      },
      color:
        textbox?.color ||
        textColor ||
        (colorVariant && colorVariant === 'light' ? 'white' : 'black'),
      button: boxLink && {
        text: decodingContent(boxLink?.text) ?? '',
        onClick: (e) => onButtonClick(e, boxLink, track, router, setIframeShown),
      },
      title: decodingContent(textbox?.headline || headline, true),
      titlePreviewId: '#st_headline',
      subtitlePreviewId: '#st_subline',
      subtitle: decodingContent(textbox?.subline ?? subline ?? subheadline, true),
      emphasize,
      scrollIndicatorText: hideScrollIndicator ? '' : translate('group_scroll'),
      boxposition: textbox?.position || position,
      video,
      videoType,
      headline,
    };

    return [...acc, newCur];
  }, []);

  return newItems;
}
