// types
import type { VideoPreviewImageType } from 'components/ContentElementsGdds/video-channel/types';

// components
import { Title } from '../Title/Title';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import ConfigurableYoutube from '../../Video/youtube/configurable-youtube';

// utils
import { TitleFormats } from '../Title/title.types';
import { useSectionId } from 'utils/hooks/use-section-id';

type TextVideoProps = {
  headlinePosition: 'content' | 'top';
  title: string;
  text: string;
  video: {
    videoId: string;
    videoPreviewImage: VideoPreviewImageType<string>;
    type?: 'mi24' | 'youtube';
    playerControl?: boolean;
    language?: string;
    showLink?: boolean;
  };
  anchor?: string;
  position?: string;
  previewId?: string;
  border?: string;
  subtitle?: string;
  titleInAnchor?: boolean;
  isSectionReference?: boolean;
  isChild?: boolean;
};

export function TextVideo({
  headlinePosition = 'top',
  titleInAnchor,
  isSectionReference,
  isChild,
  ...props
}: Readonly<TextVideoProps>) {
  const { position, border, title, subtitle, text, anchor, video } = props;
  const sectionId = useSectionId(title, anchor ?? '', titleInAnchor);

  const borderClass = border === 'disable-border' ? `c-text-video--${border}` : '';

  let positionClasses = 'c-text-video__wrapper ';
  positionClasses += position
    ? `c-text-video__wrapper--${position}`
    : 'c-text-video__wrapper--left';

  if (!video) {
    return null;
  }

  return (
    <div className="grid-container" id={sectionId}>
      <div className={`c-text-video ${borderClass}`}>
        {headlinePosition !== 'content' && (
          <Title
            title={title}
            subtitle={subtitle}
            Format={isChild ? TitleFormats.h4 : TitleFormats.h2}
            titlePreviewId="#st_title"
            subtitlePreviewId="#st_subtitle"
            isSectionReference={isSectionReference}
            formatClassName="h3"
          />
        )}
        <div className={positionClasses}>
          {video?.type === 'youtube' ? (
            <ConfigurableYoutube
              videoId={video?.videoId}
              playerControl={video?.playerControl}
              language={video?.language}
              showLink={video?.showLink}
            />
          ) : (
            <MiVideoContainer
              className="c-text-video"
              content={{ ...props }}
              isSectionReference={isSectionReference}
            />
          )}
          {headlinePosition === 'content' ? (
            <div className="c-text-video__text">
              <Title
                title={title}
                subtitle={subtitle}
                Format={isChild ? TitleFormats.h4 : TitleFormats.h2}
                formatClassName="h3"
              />
              {text && <InnerHtml as="p" content={text} />}
            </div>
          ) : (
            text && (
              <InnerHtml
                as="div"
                className="c-text-video__text"
                content={text}
                previewId="#st_text"
                isSectionReference={isSectionReference}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
