import styled, { css } from 'styled-components';

// types
import type { TextColor } from 'components/ContentElementsGdds/hero-image/hero-image-gdds.types';

export const StyledHeroImageVideoPlayer = styled.div<{
  color: TextColor;
  wideLayout?: boolean;
}>((props) => {
  const { color, theme, wideLayout } = props;

  const mobileNavHeightOffset = 60;
  const desktopNavHeightOffset = 98;

  return css`
    width: ${theme.sizes.full};
    height: ${wideLayout ? 'auto' : `calc(${theme.sizes.screenHeight} - ${mobileNavHeightOffset}px)`};

    &.wide-layout {
      aspect-ratio: 3 / 4;
      @media ${({ theme }) => theme.device.medium} {
        aspect-ratio: 16 / 6;
      }
    }

    overflow: hidden;

    background-color: ${() => (color === 'white' ? 'var(--white-80)' : 'var(--black-70)')};

    @media ${() => theme.device.medium} {
      width: ${theme.sizes.full};
      ${!wideLayout ? `min-height: calc(${theme.sizes.screenHeight} - ${mobileNavHeightOffset}px)` : ''};
    }

    @media ${() => theme.device.large} {
      width: ${theme.sizes.full};
      ${!wideLayout ? `min-height: calc(${theme.sizes.screenHeight} - ${desktopNavHeightOffset}px)` : ''};
    }
  `;
});
